<template>
  <div class="container container-md-fixed">
    <div class="row" v-show="!no_content()">
      <div class="col-8 col-xs-12">
        <div v-if="enable_ad_bar() && content_ready()">
          <div class="hidden-xs">
            <ads-block position="top"></ads-block>
          </div>
          <div class="visible-xs">
            <ads-block position="mtop"></ads-block>
          </div>
        </div>

        <div v-if="display_content()">
          <div v-for="item in items" :key="item.id"> 
            <div v-if="item.type == 'announcement'" class="panel news">
              <div class="panel-news-info">
                <p class="panel-news-info-timestamp">
                  <span v-if="item.data.urgency === 1">Urgent</span>
                  Announcement <span style="margin: 0 8px">|</span> <datetime :timestamp="item.timestamp" type="ago"></datetime>
                </p>
                <h2 class="panel-news-info-title">{{ item.title }}</h2>
                <p v-html="insertLineBreaks(item.description)"></p>
              </div>
            </div>

            <div v-if="item.type == 'news'" class="panel panel-news">
              <div class="panel-news-image" v-if="item.image_url">
                <a :href="item.link" target="_blank">
                  <img :src="item.image_url" />
                </a>
              </div>
              <div class="panel-news-info">
                <p class="panel-news-info-timestamp">
                  News <span style="margin: 0 8px">|</span> <datetime :timestamp="item.timestamp" type="ago"></datetime>
                </p>
                <a :href="item.link">
                  <h2 class="panel-news-info-title">{{ item.title }}</h2>
                </a>
                <p v-html="item.description"></p>

                <a class="panel-news-info-more" :href="item.link">
                  Read more
                  <i class="icon-arrow-right"></i>
                </a>
              </div>
            </div>

            <div v-if="item.type == 'poll' && item.options.length" class="panel panel-poll panel-padding-large">
              <div class="event-summary-info">
                <div class="event-summary-info-meta">
                  <p class="event-summary-info-timestamp">
                    Poll <span style="margin: 0 8px">|</span><datetime :timestamp="item.timestamp" type="ago"></datetime>
                  </p> 
                  <a class="event-summary-info-title">
                    {{ item.title }}
                  </a>
                </div>
              </div>

              <div v-if="item.data.voted">
                <dl v-for="option in item.options" :key="option.id">
                  <dt>{{ option.title }}</dt>
                  <dd>
                    <div class="progress">
                      <div
                        class="progress-bar channel-color"
                        role="progressbar"
                        v-bind:aria-valuenow="option.percentage"
                        aria-valuemin="0"
                        aria-valuemax="100"
                        v-bind:style="{width: option.percentage + '%'}"
                      >{{ option.percentage }}%</div>
                    </div>
                  </dd>
                </dl>

                <div class="text-center">
                  <hr />

                  <p class="text-muted">You have already voted.</p>
                </div>
              </div>
              <div v-if="!item.data.voted">
                <form @submit.prevent="submitVoting(item)">
                  <div v-for="option in item.options" :key="option.id" class="panel-poll-option">
                    <label class="form-control radio">
                      <input type="radio"  
                        :name="item.id"                          
                        :value="option.id"
                        v-model="voting[item.id]">
                      <span class="checkmark"></span>

                      {{ option.title }}
                    </label>
                  </div>

                  <div class="text-right">
                    <button type="submit" class="btn-primary">Submit your vote</button>
                  </div>
                </form>
              </div>
            </div>

            <div v-if="item.type == 'gallery'" class="panel panel-event-photos panel-padding-large">
              <div class="event-summary-info">
                <div class="event-summary-info-meta">
                  <p class="event-summary-info-timestamp">
                    Photos <span style="margin: 0 8px">|</span> <datetime :timestamp="item.timestamp" type="ago"></datetime>
                  </p> 
                  <a class="event-summary-info-title">
                    {{ item.title }}
                  </a>
                </div>
              </div>
              <div class="panel-event-photos-container">
                <div class="panel-event-photo" v-for="(photo) in item.data[0].photos" :key="photo.photo_id">
                  <a :href="`/event/${item.data[0].event_id}?open=photo_${photo.photo_id}`">
                    <img :src="photo.src" />
                  </a>
                </div>
              </div>
            </div>

            <div v-if="item.type == 'highlight'" class="panel panel-highlight">
              <div class="event-summary-info">
                <div class="event-summary-info-meta">
                  <p class="event-summary-info-timestamp">
                    Highlight <span style="margin: 0 8px">|</span> <datetime :timestamp="item.timestamp" type="ago"></datetime>
                  </p> 
                  <a class="event-summary-info-title">
                    {{ item.title }}
                  </a>
                </div>
              </div>
              <div class="iframe-container">
                <iframe :src="item.description" allowfullscreen></iframe>
              </div>
              <div>
                Originally from
                <a
                  :href="'/event/' + item.data.event_id"
                  target="_blank"
                >{{ item.data.event_name }}</a>
              </div>
            </div>

            <div v-if="item.type == 'archive'" class="panel event-summary">
              <a class="event-summary-image" :href="'/event/' + item.id">
                <img v-if="item.data.br_type == 2" :src="item.image_url" />
                <img
                  v-if="item.data.br_type != 2"
                  :src="'//media.meridix.com/thumbnails/' + item.id + '.jpg'"
                  @error="$event.target.src='/images/preview_unavailable.jpg'"
                />
                <div class="event-summary-image-meta">
                  <p class="event-summary-image-timestamp">
                    <datetime :timestamp="item.timestamp" type="ago"></datetime>
                    <template  v-if="item.data.status == 'A'">
                      <span style="padding: 0 8px">
                        |
                      </span>
                      <a v-if="item.data.status == 'A'" @click.prevent="purchase($event)" :href="'/archive_purchase.php?EventID=' + item.id + '&adding=add'">Buy Download</a>
                    </template>
                  </p>
                  <h2 class="event-summary-image-title">{{ item.title }}</h2>
                  <div 
                    class="event-summary-image-play"
                    v-if="item.link != null && item.type == 'archive'"
                  >
                    <i class="icon-play"></i>
                  </div>
                  <div class="event-summary-image-badge" v-if="item.data.status == 'L'">Live</div>
                </div>
              </a>

              <div class="event-summary-info">
                <div class="event-summary-info-meta">
                  <p class="event-summary-info-timestamp">
                    <i :class="`icon-${item.data.event_type.toLowerCase().replace(' ', '-')}`"></i>
                    {{item.data.event_type}}
                    <span style="padding: 0 8px">
                      |
                    </span>
                    <span v-if="item.data.status == 'L'">LIVE NOW</span>
                    <datetime v-else :timestamp="item.timestamp" type="ago"></datetime>
                    <template  v-if="item.data.status == 'A'">
                      <span class="hidden-xs hidden-sm" style="padding: 0 8px">
                        |
                      </span>
                      <a  @click.prevent="purchase($event)" :href="'/archive_purchase.php?EventID=' + item.id + '&adding=add'">Buy Download</a>
                    </template>
                  </p>
                  <a class="event-summary-info-title" :href="'/event/' + item.id">{{ item.title }}</a>
                </div>
                <div class="event-summary-info-teams" v-if="item.data.interface_type === 'Advanced' && item.data.event_type !== 'General' && item.data.event_type !== 'Radio Show'">
                  <div class="event-summary-info-team">
                    <div class="event-summary-info-team-score">
                      <img :src="`signup/images/logos/${item.data.away.id.toLowerCase()}.gif`" v-img-fallback="[`/mbp/login/uploads/${item.id}_a.gif`, '/images/company/logos/MRDX_Sails_Logo.jpg']" />
                      <label>{{ item.data.away.name }}</label>
                      <span>{{ item.data.away.score }}</span>
                    </div>
                    <span class="event-summary-info-team-rank">{{ item.data.game.time || '-' }}</span>
                  </div>

                  <div class="event-summary-info-team">
                    <div class="event-summary-info-team-score">
                      <img :src="`signup/images/logos/${item.data.home.id.toLowerCase()}.gif`" v-img-fallback="[`/mbp/login/uploads/${item.id}_h.gif`, '/images/company/logos/MRDX_Sails_Logo.jpg']" />
                      <label>{{ item.data.home.name }}</label>
                      <span>{{ item.data.home.score }}</span>
                    </div>
                    <span class="event-summary-info-team-rank">{{ formatPeriod(item.data.game.period)  || '-'  }}</span>
                  </div>
                </div>

                <!-- <template v-if="item.data.status == 'L' || item.data.archived == 'Y'">
                  <a
                    @click.prevent="purchase($event)"
                    :href="'/archive_purchase.php?EventID=' + item.id + '&adding=add'"
                    class="btn btn-secondary text-center"
                  >
                    <i class="icon-process-download"></i> Buy Download
                  </a>
                </template> -->
              </div>
            </div>
          </div>

          <ajax-loader :hide="!is_pending()" inline="true"></ajax-loader>

          <div v-if="has_more() && !is_pending()">
            <a class="btn btn-secondary width-full btn-xs text-center channel-page-more" @click="fetch">Load more</a>
          </div>
        </div>

        <div v-if="enable_ad_bar() && content_ready()">
          <div class="hidden-xs">
            <ads-block position="bottom"></ads-block>
          </div>
        </div>
      </div>

      <div class="col-4 col-xs-12">
        <div v-if="display_content()">

          <!--Scheduled items-->
          <recent-items 
            :items="recent"
            root="/schedule" 
            title="Scheduled"
            name="scheduled events">
          </recent-items>

          <recent-items
            v-if="content.news.length"
            :items="content.news"
            title="News"
            name="News"
            root="/news"
          ></recent-items>
          <recent-items
            v-if="content.highlights.length"
            :items="content.highlights"
            title="Highlights"
            name="Highlights"
          ></recent-items>
          <event-photos
            v-if="content.photos.length"
            :items="content.photos">
          </event-photos>

          <signup-block></signup-block>
          <div class="panel">
            <div class="panel-body text-center">
              <add-this></add-this>
            </div>
          </div>
          <div class="hidden-xs">
            <ads-block position="right" v-if="enable_ad_bar() && content_ready()"></ads-block>
          </div>
        </div>
      </div>
    </div>
    <ajax-loader :hide="is_ready()"></ajax-loader>
    <message
      text="Nothing published yet. Create your first broadcast today!"
      :show="no_content() && is_ready()"
    ></message>
    
    <div v-if="enable_ad_bar() && content_ready()" class="visible-xs">
      <ads-block position="mbottom"></ads-block>
    </div>

  </div>
</template>

<script>
import Paginatable from "../components/custom/paginatable";
import AdBar from "../components/custom/ad-bar";

export default {
  data() {
    return {
      voting: {},
      liveid: this.$store.getters.liveid,
      resource: "/feed.php",
      links: {
        gallery: "photos",
        news: "news",
        highlight: "highlights"
      },
      content: {
        news: [],
        highlights: [],
        photos: []
      },
      gamesWithScore: [
        "baseball",
        "basketball",
        "football",
        "hockey",
        "soccer",
        "softball",
        "volleyball",
        "wrestling"
      ]
    };
  },
  methods: {
    insertLineBreaks(string){
      return string.replace(/(?:\r\n|\r|\n)/g,"<br />");
    },
    getLink(item) {
      return this.links[item.type] + "/" + item.id;
    },
    submitVoting(item) {
      var t = this;

      if (this.voting[item.id]) {
        this.$http
          .get("content/polls.php", {
            params: {
              liveid: this.liveid,
              poll_id: item.id,
              option_id: this.voting[item.id]
            }
          })
          .then(response => {
            if (response.body.item) {
              for (var index in t.items) {
                if (t.items[index].id == item.id) {
                  var i = t.items[index];
                  i.data.voted = true;
                  i.options = response.body.item.options;

                  t.items.slice(index, 1, i);
                  return;
                }
              }
            }
          });
      }
    },
    formatPeriod(period) {
      if (period) {
        var parts = period.split(' ');
        if (parts.length) {
          return parts[0];
        }
      }

      return period;
    },
    showGameInfo(item) {
      return (
        this.gamesWithScore.indexOf(item.data.event_type.toLocaleLowerCase()) !=
        -1
      );
    },
    purchase(event) {
      $.fancybox.open({
        type: "iframe",
        src: event.target.href,
        autosize: false,
        iframe: {
          css: {
            width: "1128px",
            maxWidth: "90%",
            maxHeight: "90%"
          }
        }
      });
    },
    loaded(response) {
      if (response.content) {
        this.content.highlights = response.content.highlights;
        this.content.news = response.content.news;
        this.content.photos = response.content.photos;
      }
    }
  },
  mixins: [Paginatable, AdBar]
};
</script>
