export default {
    data() {
        return {
            items: [],
            recent: [],
            single: false,
            offset: 0,
            step: 10,
            is_more: false,
            pending: false
        }
    },
    methods: {
        init() {
            this.offset = 0;
            this.is_more = false;
            this.pending = false;

            this.items = null;

            this.single = (this.$route.params.id != undefined);
            this.fetch();
        },
        loaded() {},
        has_more() {
            return !this.single && this.is_more;
        },
        no_content() {
            return this.items == null || this.items.length == 0;
        },
        is_ready() {
            return this.items != null;
        },
        is_pending()
        {
            return this.pending;
        },
        display_content() {
            return this.items != null && this.items.length > 0;
        },
        content_ready() {
            return this.items != null;
        },
        fetch() {

            this.pending = true;

            this.$http.get('content' + this.resource, {
                params: {
                    id: this.$route.params.id,
                    liveid: this.$store.getters.liveid,
                    recent: (this.recent == null),
                    offset: this.offset
                }
            }).then(response => {

                if (response.body.items)
                    this.items = this.items
                        ? this.items.concat(response.body.items)
                        : response.body.items;

                if (response.body.recent && (this.recent == null || this.recent.length == 0))
                     this.recent = response.body.recent;

                this.offset += this.step;
                this.is_more = response.body.is_more;

                this.pending = false;
                this.loaded(response.body);

                if (response.body._has_live_events != undefined) {

                    this.$store.commit('SET_LIVE_EVENTS', response.body._has_live_events);
                }
            });
        }
    },
    created() { this.init() },
    watch: { '$route': 'init' }
}