<template>
  <div class="container" style="flex: 0 0 auto">
    <div class="channel-info-block channel-info-block-left">
      <div class="channel-info-block-logo" v-if="logo_url">
        <router-link :to="{path: '/'}">
          <img :src="logo_url" v-img-fallback="['/images/company/logos/MRDX_Sails_Logo.jpg']" width="48" height="48" />
        </router-link>
      </div>
      <div>
        <h1 class="channel-info-block-title">{{ name }}</h1>
        <span class="channel-info-block-subtitle">{{ location }}</span>
      </div>

      <div class="channel-info-block-actions hidden-xs">
        <a :href="contact_website" v-if="contact_website">
          <i class="icon icon-globe"></i>
        </a>

        <a href="#/contact-us">
          <i class="icon icon-envelope"></i>
        </a>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return this.$store.getters.info;
  },
  computed: {
    // todo: do we still need this?
    // is_live() {
    //     return this.$store.getters.has_live_events;
    // }
  }
};
</script>