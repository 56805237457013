import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter);

import Feed from '../pages/Feed.vue'
import Archives from '../pages/Archives.vue'
import Schedule from '../pages/Schedule.vue'
import Photos from '../pages/Photos.vue'
import News from '../pages/News.vue'
import ContactUs from '../pages/ContactUs.vue'

const routes = [
    { path: '', component: Feed },
    { path: '/archives', component: Archives },
    { path: '/schedule/:id?', component: Schedule },
    { path: '/news/:id?', component: News },
    { path: '/photos/:id?', component: Photos },
    { path: '/contact-us', component: ContactUs }
];

const router = new VueRouter({routes: routes});

router.beforeEach(function (to, from, next) {
    window.scrollTo(0, 0);
    next();
});

export default router;