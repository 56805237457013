<template>
  <div class="container">
    <div class="row">
      <div class="col-8">
        <div v-if="enable_ad_bar() && is_ready()">
          <div class="hidden-xs">
            <ads-block position="top"></ads-block>
          </div>
          <div class="visible-xs">
            <ads-block position="mtop"></ads-block>
          </div>
        </div>

        <div v-if="display_content()">
          <div v-for="item in items" :key="item.id" class="panel panel-news">
            <div class="panel-news-image" v-if="item.image_url && !single">
              <router-link :to="{to: '/:id', params: {'id': item.id}}">
                <img
                  :src="item.image_url"
                  @error="$event.target.src='/images/preview_unavailable.jpg'"
                />
              </router-link>
            </div>

            <div class="panel-news-info">
              <router-link :to="{to: '/:id', params: {'id': item.id}}">
                <h2 class="panel-news-info-title">{{ item.title }}</h2>
              </router-link>

              <p class="panel-news-info-timestamp">
                    {{item.local_datetime}}
                    <span v-if="single && item.timezone">{{ item.timezone_abbr }}</span>
                    <span>- by {{ item.author }}</span>
                    <span>- {{ item.agency }}</span>
              </p>

              <!-- <img :src="item.image_url" @error="$event.target.src='/images/preview_unavailable.jpg'" v-if="single" /> -->
              <img
                class="panel-news-single-image"
                :src="item.image_url"
                @error="$event.target.src='/images/preview_unavailable.jpg'"
                v-if="single"
              />

              <p v-if="!single" v-html="item.short_body"></p>
              <p
                v-if="single"
                style="white-space: pre-line; text-align: justify"
              >
                <strong v-if="item.city" class="text-uppercase">{{ item.city }},</strong>
                <strong v-if="item.region">{{ item.region }}</strong>
                <span v-if="item.city"> - </span>

                <span v-html="item.body"></span>
              </p>

              <router-link
                :to="{to: '/:id', params: {'id': item.id}}"
                v-if="!single"
                class="panel-news-info-more"
              >
                Read more
                <i class="icon-arrow-right"></i>
              </router-link>
            </div>
          </div>

          <ajax-loader :hide="!is_pending()" inline="true"></ajax-loader>

          <div v-if="has_more() && !is_pending()">
            <a class=" btn btn-secondary width-full btn-xs text-center channel-page-more" @click="fetch">Load more</a>
          </div>
        </div>

        <div v-if="enable_ad_bar() && content_ready()">
          <div class="hidden-xs">
            <ads-block position="bottom"></ads-block>
          </div>
        </div>
      </div>

      <div class="col-4 col-xs-12">
        <div v-if="display_content()">
          <recent-items v-if="single" :items="recent" title="Recent News" name="News" root="/news"></recent-items>
          <signup-block></signup-block>
          <div class="panel">
            <div class="panel-body text-center">
              <add-this></add-this>
            </div>
          </div>
          <div class="hidden-xs">
            <ads-block position="right"></ads-block>
          </div>
        </div>
      </div>
    </div>
    <ajax-loader :hide="is_ready()"></ajax-loader>
    <message text="No news found" :show="no_content() && is_ready()"></message>
    <div v-if="enable_ad_bar() && !no_content()" class="visible-xs">
      <ads-block position="mbottom"></ads-block>
    </div>
  </div>
</template>

<script>
import Paginatable from "../components/custom/paginatable";
import AdBar from "../components/custom/ad-bar";

export default {
  data() {
    return {
      resource: "/news.php"
    };
  },
  mixins: [Paginatable, AdBar]
};
</script>
