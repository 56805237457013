<template>
  <div class="page-container channel-page">
    <main-menu></main-menu>
    <main-background></main-background>
    <main-header></main-header>

    <router-view></router-view>

    <main-footer></main-footer>
  </div>
</template>

<script>
    import Vue from 'vue'
    import MainMenu from './components/main/Menu.vue'
    import MainBackground from './components/main/Background.vue'
    import MainHeader from './components/main/Header.vue'
    import MainFooter from './components/main/Footer.vue'

    import CustomRecentItems from './components/custom/recent.vue'
    import CustomEventPhotos from './components/custom/event-photos.vue'
    import CustomDateTime from './components/custom/datetime'
    import CustomAjaxLoader from './components/custom/ajax-loader'
    import CustomMessage from './components/custom/message'
    import CustomAdsBlock from './components/custom/ads-block.vue'
    import CustomSignUpBlock from './components/custom/signup-block.vue'
    import CustomAddThis from './components/custom/add-this.vue'

    export default {
        components: {
            'main-header': MainHeader,
            'main-background': MainBackground,
            'main-menu': MainMenu,
            'main-footer': MainFooter,

            'ads-block': CustomAdsBlock,
            'add-this': CustomAddThis,
            'recent-items': CustomRecentItems,
            'event-photos': CustomEventPhotos,
            'datetime': CustomDateTime,
            'ajax-loader': CustomAjaxLoader,
            'message': CustomMessage,
            'signup-block': CustomSignUpBlock
        },
        computed: {
            enable_ad_bar() { return this.$store.getters.ad_mode != null; }
        },
        created() {

            this.$store.commit('SET_LIVE_INFO', {
                liveid: window.meridix.channel.liveid,
                name: window.meridix.channel.name,
                location: window.meridix.channel.location,
                logo_url: window.meridix.channel.logo_url,
                social_links: window.meridix.channel.social_links,
                header_url: window.meridix.channel.header_url,
                contact_website: window.meridix.channel.contact_website
            });

            this.$store.commit('SET_AD_MODE', window.meridix.channel.ad_mode);
            this.$store.commit('SET_AD_BANNERS', window.meridix.channel.ad_banners);
        }
    }
</script>