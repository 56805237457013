import Vue from 'vue';
import VueResource from 'vue-resource'

import App from './App.vue'
import router from './components/router'
import store from './components/store'
import ImgFallback from './components/custom/img-fallback'

Vue.use(VueResource);
Vue.use(ImgFallback);

new Vue({
  router,
  store,
  el: '#app',
  render: h => h(App)
});