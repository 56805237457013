<template>
  <div class="container">
    <div class="row row-md-fixed">
      <div class="col-8">
        <div v-if="enable_ad_bar() && content_ready()">
          <div class="hidden-xs">
            <ads-block position="top"></ads-block>
          </div>
          <div class="visible-xs">
            <ads-block position="mtop"></ads-block>
          </div>
        </div>

        <div v-if="display_content()">
          <div
            v-for="item in items"
            :key="item.id"
            class="panel panel-event-photos panel-padding-large"
          >
            <div class="panel-header">
              <h3>{{ item.title }}</h3>
              <a :href="'#/photos/' + item.id">
                View all {{ item.count_of_photos }} photos
                <i class="icon-arrow-right"></i>
              </a>
            </div>
            <div class="panel-event-photos-container">
              <div class="panel-event-photo" v-for="(photo) in item.photos" :key="photo">
                <a :href="photo" v-bind:data-lightbox="item.id">
                  <img :src="photo" />
                </a>
              </div>
            </div>
          </div>

          <ajax-loader :hide="!is_pending()" inline="true"></ajax-loader>

          <div v-if="has_more() && !is_pending()">
            <a class="btn btn-secondary width-full btn-xs text-center channel-page-more" @click="fetch">Load more</a>
          </div>
        </div>

        <div v-if="enable_ad_bar() && content_ready()">
          <div class="hidden-xs">
            <ads-block position="bottom"></ads-block>
          </div>
        </div>
      </div>

      <div class="col-4">
        <div v-if="display_content()">
          <recent-items root="/photos" :items="recent" name="Photo albums"></recent-items>

          <signup-block></signup-block>
          <div class="panel">
            <div class="panel-body text-center">
              <add-this></add-this>
            </div>
          </div>
          <div class="hidden-xs">
            <ads-block position="right"></ads-block>
          </div>
        </div>
      </div>
    </div>
    
    <div v-if="enable_ad_bar() && content_ready()" class="visible-xs">
      <ads-block position="mbottom"></ads-block>
    </div>
    <ajax-loader :hide="is_ready()"></ajax-loader>
    <message text="No photos found" :show="no_content() && is_ready()"></message>
  </div>
</template>

<script>
import Paginatable from "../components/custom/paginatable";
import AdBar from "../components/custom/ad-bar";

export default {
  data() {
    return {
      resource: "/photos.php"
    };
  },
  mixins: [Paginatable, AdBar]
};
</script>
