<template id="recent-items">
  <div v-if="items" class="panel news-list">
    <div class="panel-header">
      <h3>{{ _title }}</h3>

      <template v-if="hasMoreItems() && root">
        <router-link v-bind:to="root">
          {{ _more }}
          <i class="icon-arrow-right"></i>
        </router-link>
      </template>
    </div>
    <div class="news-list-body">
      <p v-if="items.length == 0" class="text-muted text-center">{{ _empty }}</p>

      <div v-for="item in _items" class="news-item">
        <!-- todo: find a cleaner way of dealing with absolute urls -->
        <a :href="item.event_url" v-if="item.event_url">
          <div class="news-item-image" v-if="item.image_url || item.event_type">
              <img 
                  v-if="item.image_url" 
                  :src="item.image_url" 
                  v-img-fallback="['/images/company/logos/MRDX_Sails_Logo.jpg']"
              />
              <img
                  v-else-if="item.br_type == 3 && item.highlight_id"
                  :src="`//media.meridix.com/thumbnails/highlight_${item.highlight_id}.jpg`"
                  v-img-fallback="['/images/company/logos/MRDX_Sails_Logo.jpg']"
              />
              <i
                  v-else-if="item.event_type && !item.highlight_id"
                  :class="`icon-${item.event_type.toLowerCase()}`"
              ></i>
              <img v-else-if="item.br_type == 2" src="/images/audio-placeholder.png" />
          </div>
          <div class="news-item-info">
            <p class="news-item-info-timestamp">
              <span v-if="item.local_date">
                {{ item.local_date }}
                <span v-if="item.local_time">
                  @ {{ item.local_time }}
                  <span v-if="item.timezone_abbr">{{ item.timezone_abbr }}</span>
                </span>
              </span>
              <span v-else-if="item.timestamp">
                {{ date(item) }}
              </span>
            </p>
            <p class="news-item-info-title">{{ item.title }}</p>
          </div>
        </a>
        <router-link v-bind:to="url(item)" v-else>
          <div class="news-item-image" v-if="item.image_url || item.event_type">
            <img 
                v-if="item.image_url" 
                :src="item.image_url" 
                v-img-fallback="['/images/company/logos/MRDX_Sails_Logo.jpg']"
            />
            <img
                v-else-if="item.br_type == 3 && item.highlight_id"
                :src="`//media.meridix.com/thumbnails/highlight_${item.highlight_id}.jpg`"
                v-img-fallback="['/images/company/logos/MRDX_Sails_Logo.jpg']"
            />
            <i
                v-else-if="item.event_type && !item.highlight_id"
                :class="`icon-${item.event_type.toLowerCase()}`"
            ></i>
          </div>
          <div class="news-item-info">
            <p class="news-item-info-timestamp">
              <span v-if="item.local_date">
                {{ item.local_date }}
                <span v-if="item.local_time">
                  @ {{ item.local_time }}
                  <span v-if="item.timezone_abbr">{{ item.timezone_abbr }}</span>
                </span>
              </span>
              <span v-else-if="item.timestamp">
                {{ date(item) }}
              </span>
            </p>
            <p class="news-item-info-title">{{ item.title }}</p>
          </div>
        </router-link>
      </div>
    </div>
  </div>
</template>

<script>
import Vue from "vue";
import moment from "moment";

export default Vue.component("recent-items", {
  props: ["name", "title", "empty", "items", "length", "root"],
  methods: {
    date(item) {
      return moment.unix(item.timestamp).format("MMM D, YYYY");
    },
    url(item) {
      return this.root != undefined
        ? { path: this.root + (item.id ? "/" + item.id : "") }
        : { path: "/" + item.id };
    },
    hasMoreItems() {
      return this.max && this.items.length > this.max;
    }
  },
  computed: {
    _items() {
      return this.max ? this.items.slice(0, this.max) : this.items;
    },
    _title() {
      return this.title ? this.title : "Recent " + this.name.toLowerCase();
    },
    _empty() {
      return this.empty ? this.empty : "No " + this.name.toLowerCase();
    },
    _more() {
      return this.name ? "More " + this.name : "More";
    },

    max() {
      return this.length ? this.length : 5;
    }
  },
  template: "#recent-items"
});
</script>